<template>

    <div class="signin-body">
        <div class="form-signin">
        <!--
            <img class="mb-4" src="../../assets/logos/bootstrap/bootstrap-solid.svg" alt="" width="72" height="72">
        -->
            <img class="mb-4" src="@/assets/logo.png" alt="" width="160">
            <h1 class="h3 mb-3 font-weight-normal">Please register</h1>

            <!-- Display server error messages, from server validation -->
            <!-- Diplay alert message received from backend -->
            <AltertMessage
                v-bind:data="formUtilsComp.state.form.response"
            ></AltertMessage>

            <!-- Display server exceptions, from database -->
            <ExceptionMessage
                v-bind:exception="formUtilsComp.state.form.exception"
            ></ExceptionMessage>

            <div class="form-group mb-3">
                <input
                    v-model="authComp.state.form.body.name"
                    @keyup.enter="registerComp()"
                    type="text"
                    id="inputName"
                    class="form-control"
                    v-bind:class="formUtilsComp.isFieldEmpty(authComp.state.form.body.name) ? 'is-invalid' : 'is-valid'"
                    placeholder="Name"
                    required autofocus
                >
                <div v-if="formUtilsComp.isFieldEmpty(authComp.state.form.body.name)" class="text-info text-sm">Please fill up with your name</div>
                <!-- Diplay alert message received from backend -->
                <ValidationErrors v-bind:errors="formUtilsComp.state.form.errors.name"></ValidationErrors>
            </div>

            <div class="form-group mb-3">
                <input
                    v-model="authComp.state.form.body.email"
                    @keyup.enter="registerComp"
                    type="email"
                    id="inputEmail"
                    class="form-control"
                    v-bind:class="formUtilsComp.isEmailValid(authComp.state.form.body.email) ? 'is-valid' : 'is-invalid'"
                    placeholder="Email address"
                    required autofocus
                >
                <div v-if="!formUtilsComp.isEmailValid(authComp.state.form.body.email)" class="text-info text-sm">Please enter a valid email address</div>
                <!-- Diplay alert message received from backend -->
                <ValidationErrors v-bind:errors="formUtilsComp.state.form.errors.email"></ValidationErrors>
            </div>

            <div class="form-group mb-3">
                <input
                    v-model="authComp.state.form.body.password"
                    :type="authComp.state.form.show_password ? 'text' : 'password'"
                    @keyup.enter="registerComp"
                    id="inputPassword"
                    class="form-control"
                    v-bind:class="formUtilsComp.isPasswordEmpty(authComp.state.form.body.password) ? 'is-invalid' : 'is-valid'"
                    placeholder="Password" required
                >
                <div v-if="formUtilsComp.isPasswordEmpty(authComp.state.form.body.password)" class="text-info text-sm">Please enter your password</div>
                <!-- Diplay alert message received from backend -->
                <ValidationErrors v-bind:errors="formUtilsComp.state.form.errors.password"></ValidationErrors>
            </div>

            <div class="form-group mb-3">
                <input
                    v-model="authComp.state.form.body.password_confirmation"
                    :type="authComp.state.form.show_password ? 'text' : 'password'"
                    @keyup.enter="registerComp"
                    id="inputPasswordConfirmation"
                    class="form-control"
                    v-bind:class="formUtilsComp.passwordsMatch(authComp.state.form.body.password, authComp.state.form.body.password_confirmation) ? 'is-valid' : 'is-invalid'"
                    placeholder="Password confirmation" required
                >
                <div v-if="!formUtilsComp.passwordsMatch(authComp.state.form.body.password, authComp.state.form.body.password_confirmation)" class="text-info text-sm">Passwords do not match</div>
                <ValidationErrors v-bind:errors="formUtilsComp.state.form.errors.password_confirmation"></ValidationErrors>
            </div>

            <div class="form-group">
                <div class="checkbox mb-1">
                    <label>
                        <input
                            v-model="authComp.state.form.show_password"
                            type="checkbox"
                            value="show-password"
                        > Show password
                    </label>
                </div>
            </div>

<!--
            <div class="form-group">
                <div class="checkbox mb-1">
                    <label>
                        <input
                            v-model="state.form.body.tos_pp"
                            type="checkbox"
                            value="tos_pp"
                        > Accept terms of service.
                    </label>
                </div>
            </div>


            <div class="form-group">
                <div class="checkbox mb-1">
                    <label>
                        <input
                            v-model="state.form.staySignedIn"
                            type="checkbox"
                            value="stay-signed-in"
                        > Stay Signed In
                    </label>
                </div>
            </div>
-->

            <div class="form-group">
                <div class="checkbox mb-1">
                    <label>
                        <input
                            v-model="authComp.state.form.remember_me"
                            type="checkbox"
                            value="remember-me"
                        > Remember me
                    </label>
                </div>
            </div>

            <div class="form-group">
                <div class="checkbox mb-3">
                    <label>
                        <input
                            v-model="authComp.state.form.fetchUser"
                            type="checkbox"
                            value="fetch-user"
                        > Fetch User
                    </label>
                </div>
            </div>



            <div class="form-group">
                <div class="checkbox mb-3">
                    <label>
                        <input
                            v-model="authComp.state.form.autoLogin"
                            type="checkbox"
                            value="auto-login"
                        > Auto Login
                    </label>
                </div>
            </div>

        <!--
            <button
                @click="registerDefault"
                class="btn btn-lg btn-primary btn-block"
            >Register default</button>

            <button
                @click="registerRedirect"
                class="btn btn-lg btn-secondary btn-block"
                type="submit"
            >Register "Redirect"</button>
        -->


            <button
                @click="registerComp"
                class="btn btn-lg btn-primary btn-block"
                type="submit"
            >Register</button>



<!--
            <button
                @click="registerDefault"
                class="btn btn-lg btn-primary btn-block"
            >Register "Default"</button>

            <button
                @click="registerRedirect"
                class="btn btn-lg btn-secondary btn-block"
                type="submit"
            >Register "Redirect"</button>

            <button
                @click="registerThen"
                class="btn btn-lg btn-secondary btn-block"
                type="submit"
            >Register "Then"</button>

            <button
                @click="registerComp"
                class="btn btn-lg btn-secondary btn-block"
                type="submit"
            >Register "Comp"</button>

-->
            <hr>

            <div class="mt-3">
                <router-link  :to="{ name : 'auth-login' }">Back to login</router-link>
            </div>

            <hr>

        </div>
    </div>


</template>

<script>
// @ is an alias to /src

//import {useStore } from 'vuex';
import {useRouter} from 'vue-router';
import {useAuth  } from '@websanova/vue-auth/src/v3.js';

// import composables files
import useAuthComp from '@/composables/useAuthComp.js';
import useFormUtilsComp from '@/composables/useFormUtilsComp.js';

// import informations and errors messages components
import AltertMessage from '@/components/messages/altertMessage.vue'
import ExceptionMessage from '@/components/messages/exceptionMessage.vue'
import ValidationErrors from '@/components/messages/validationErrors.vue'

export default {

    name: 'Register',

    components : {
        AltertMessage,
        ValidationErrors,
        ExceptionMessage,
    },

    setup() {
        const auth     = useAuth();
        //const store    = useStore();
        const router   = useRouter();
        const authComp = useAuthComp();
        const formUtilsComp = useFormUtilsComp()

        function registerDefault() {

            formUtilsComp.useResetErrors()

            auth
            .register({
                data: authComp.state.form.body,
                remember: authComp.state.form.remember_me ? '{"name": "Default"}' : null,
                fetchUser: authComp.state.form.fetchUser,
                autoLogin: authComp.state.form.autoLogin,
                staySignedIn: authComp.state.form.staySignedIn,
            })
            .then(null, (err) => {
                formUtilsComp.useSetErrors(err.response.data)
            });
        }

        function registerRedirect() {
            auth
            .register({
                data: authComp.state.form.body,
                //redirect: {name: 'user-account'},
                redirect: {name: 'logged-user'},
                remember: authComp.state.form.remember_me ? '{"name": "Redirect"}' : null,
                fetchUser: authComp.state.form.fetchUser,
                autoLogin: authComp.state.form.autoLogin,
                staySignedIn: authComp.state.form.staySignedIn,
            })
            .then(null, (err) => {
                formUtilsComp.useSetErrors(err.response.data)
            });
        }

        function registerThen() {
            auth
            .register({
                data: authComp.state.form.body,
                fetchUser: authComp.state.form.fetchUser,
                autoLogin: authComp.state.form.autoLogin,
                staySignedIn: authComp.state.form.staySignedIn,
            })
            .then(() => {
                if (authComp.state.form.remember_me) {
                    auth.remember(JSON.stringify({
                        name: auth.user().first_name
                    }));
                }

                router.push({name: 'user-account'});
            }, (err) => {
                formUtilsComp.state.form.errors = err.response.data.errors
                formUtilsComp.useMessages(err.response.data, 'alert-danger')
            });
        }

        function registerComp() {

            authComp
            .register({
                body: authComp.state.form.body,
                remember: authComp.state.form.remember_me,
                fetchUser: authComp.state.form.fetchUser,
                autoLogin: authComp.state.form.autoLogin,
                staySignedIn: authComp.state.form.staySignedIn,
            })
            .then(null, (err) => {
                formUtilsComp.state.form.errors = err.response.data.errors
                formUtilsComp.useMessages(err.response.data, 'alert-danger')
            });
        }

        return {
            registerThen,
            registerComp,
            registerDefault,
            registerRedirect,
            authComp,
            formUtilsComp,
        };
    }
}
</script>

<style>
    @import '../../assets/css/signin.css';
</style>
